import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import React, { Suspense, lazy } from 'react';
// import { DataProvider } from './DataContext';

// Lazy load components
const Home = lazy(() => import('./Home'));
const People = lazy(() => import('./People'));
const Publications = lazy(() => import('./Publications'));
const JoinPage = lazy(() => import('./Apply'));
const News = lazy(() => import('./News'));
const GalleryPage = lazy(() => import('./Gallery'));
const Admin = lazy(() => import('./Admin'));
// const ResearchPage = lazy(() => import('./Research'));
const GroupPage = lazy(() => import('./Group'));
const Secret = lazy(() => import('./Secret'));

function App() {
  return (
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/people" element={<People />} />
            <Route path="/publications" element={<Publications />} />
            <Route path="/join" element={<JoinPage />} />
            <Route path="/news" element={<News />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/admin" element={<Admin />} />
            {/* <Route path="/research" element={<ResearchPage />} />  */}
            <Route path="/groups" element={<GroupPage />} />
            <Route path="/secret" element={<Secret />} />
          </Routes>
        </Suspense>
      </Router>
  );
}

export default App;
